<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="submit()">
                  <h1>Login</h1>
                  <p class="text-muted">Masuk ke akunmu</p>
                  <CInput
                    placeholder="Email / No HP"
                    type="text"
                    :isValid="formValidation.user.isValid"
                    :invalid-feedback="formValidation.user.message"
                    v-model="form.user"
                    autofocus
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>

                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    :isValid="formValidation.password.isValid"
                    :invalid-feedback="formValidation.password.message"
                    v-model="form.password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton type="submit" color="primary" class="px-4">
                        Masuk
                      </CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <router-link :to="'/auth/forgot_password'">
                        <CButton color="link" class="px-0" lin>
                          Lupa password?
                        </CButton>
                      </router-link>

                      <router-link :to="'/auth/register'">
                        <CButton color="link" class="d-lg-none">
                          Buat Akun Baru!
                        </CButton>
                      </router-link>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Buat Akun Baru</h2>
                <router-link :to="'/auth/register'">
                  <CButton color="light" variant="outline" size="lg">
                    Daftar Sekarang!
                  </CButton>
                </router-link>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  data: () => ({
    form: {
      user: null,
      password: "whatsapp",
    },
    formValidation: {
      user: {
        isValid: null,
        message: null,
      },
      password: {
        isValid: null,
        message: null,
      },
    },
  }),

  methods: {
    async submit() {
      this.formValidation.user.isValid = null;
      this.formValidation.user.message = null;
      this.formValidation.password.isValid = null;
      this.formValidation.password.message = null;

      let loader = await this.$loading.show();
      try {
        const response = await axios.post(
          process.env.VUE_APP_API + "/auth/login",
          this.form
        );
        if (!response.data.status) {
          await this.$notify({
            type: "error",
            text: "Login gagal",
          });
        } else {
          await this.$store.commit("INITIATE_LOGIN", response.data.data.token);
          await this.$router.push("/dashboard");
        }
        loader.hide();
      } catch (error) {
        if (typeof error.response.data.errors !== "undefined") {
          const errors = error.response.data.errors;
          if (errors.user) {
            this.formValidation.user.isValid = false;
            this.formValidation.user.message = errors.user.join(" ");
          }
          if (errors.password) {
            this.formValidation.password.isValid = false;
            this.formValidation.password.message = errors.password.join(" ");
          }

          await this.$notify({
            type: "error",
            text: "Periksa lagi isian anda",
          });
        }
        if (typeof error.response.data.message !== "undefined") {
          await this.$notify({
            type: "error",
            text: error.response.data.message,
          });
        }
        loader.hide();
      }
    },
  },
};
</script>
